import React from "react"
import Img from "gatsby-image"
import { PaperClipIcon } from "@heroicons/react/solid"
import { Link } from "gatsby"
import { Layout } from "../components/layout"
import { SEO } from "../components/seo"
import { ExternalLink } from "../components/ds"

const Page = () => {
  return (
    <>
      <SEO title="Styrelsen" />
      <Layout>
        <div className="bg-white">
          <div className="max-w-screen-xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
            <div className="lg:grid lg:grid-cols-2 lg:gap-8 lg:items-start">
              <div>
                <h2 className="text-3xl leading-9 font-extrabold text-warm-grey-900 sm:text-4xl sm:leading-10">
                  Styrelsen
                </h2>
                <p className="mt-3 max-w-3xl text-lg leading-7 text-warm-grey-500">
                  Här finner du vilka personer som sitter med i styrelsen för
                  Förskolan Brandbilen. Om du vill kontakta styrelsen kan du
                  skicka ett mejl till{" "}
                  <ExternalLink
                    href="mailto: styrelsen@forskolanbrandbilen.se"
                    target="_top"
                  >
                    styrelsen@forskolanbrandbilen.se
                  </ExternalLink>
                  .
                </p>
                <div className="mt-8 sm:flex">
                  <div className="rounded-md shadow">
                    <Link
                      to="/kontakt"
                      className="flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-bb-red-600 hover:bg-bb-red-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
                    >
                      Kontakta oss
                    </Link>
                  </div>
                  <div className="mt-3 sm:mt-0 sm:ml-3">
                    <Link
                      to="/om-brandbilen"
                      className="flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-bb-red-700 bg-bb-red-100 hover:text-bb-red-600 hover:bg-bb-red-50 focus:outline-none focus:shadow-outline focus:border-bb-red-300 transition duration-150 ease-in-out"
                    >
                      Läs mer om Brandbilen
                    </Link>
                  </div>
                </div>
              </div>
              <div>
                <div>
                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                    Medlemmar
                  </h3>
                  <p className="mt-1 max-w-2xl text-sm text-gray-500">
                    Nuvarande styrelsemedlemmar.
                  </p>
                </div>
                <div className="mt-5 border-t border-gray-200">
                  <dl className="sm:divide-y sm:divide-gray-200">
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                      <dt className="text-sm font-medium text-gray-500">
                        Ordförande
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        Martin Persson
                      </dd>
                    </div>
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                      <dt className="text-sm font-medium text-gray-500">
                        Sekreterare, vice ordförande
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        Andrea Rödfeldt
                      </dd>
                    </div>
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                      <dt className="text-sm font-medium text-gray-500">
                        Personalansvarig
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        Felicia Källarsson
                      </dd>
                    </div>
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                      <dt className="text-sm font-medium text-gray-500">
                        Personalansvarig
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        Victoria Lindgren
                      </dd>
                    </div>
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                      <dt className="text-sm font-medium text-gray-500">
                        Kassör/ekonomiansvarig
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        Mona Lundqvist
                      </dd>
                    </div>
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                      <dt className="text-sm font-medium text-gray-500">
                        Suppleant
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        Anette Nyström
                      </dd>
                    </div>
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4">
                      <dt className="text-sm font-medium text-gray-500">
                        Suppleant
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                        Sanna Röjestål
                      </dd>
                    </div>
                  </dl>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default Page
